import React, { FC } from 'react'
import FormInput from '../../../kyc-front/ui-elements/FormInput'
import FormSelect from '../../../kyc-front/ui-elements/FormSelect'
import { FormCheckboxRadio } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { Divider, Space, Row, Col, Select } from 'antd'
import { countries } from 'countries-list'
import { Regions } from '../../../../utilities/Regions'

interface CProps {
    errors: any,
    values: any,
    showApprove: boolean,
    handleInputChange: () => void,
    onSelectChange: (val: string, name: string) => void,
    disabled: boolean,
    printRender: boolean,
    isBusiness: boolean,
    namePrefix?: string
}


const PurposeOfEntity: FC<CProps> = ({showApprove, errors, handleInputChange, onSelectChange, disabled, values, printRender, isBusiness, namePrefix}) => {
	const { Option } = Select;
	const countriesAndRegions = {...Regions, ...countries};
	return (
		<Space direction='vertical' size={24}>
      		{isBusiness && <FormInput className={printRender && isBusiness ? 'naturePrint' : ''} showApprove={showApprove} label='Nature of business activities and business model' subLabel='(please explain in 2-3 sentences)'  name={namePrefix ? namePrefix  + '.purposeOfEntity.businessDescription' : 'purposeOfEntity.businessDescription'} error={errors.purposeOfEntity?.businessDescription} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.purposeOfEntity?.businessDescription} printRender={printRender} type='company' />}
      		{isBusiness && values.purposeOfEntity.countriesOfOperation !== undefined && <Row gutter={30}>
                    <Col span={24}>
                        <FormSelect hideApprove={!showApprove} mode="multiple" defaultValue={values.purposeOfEntity?.countriesOfOperation !== '' ? values.purposeOfEntity?.countriesOfOperation?.split(',') : values.purposeOfEntity.countriesOfOperation} label='Countries and regions of operation' id={namePrefix ? namePrefix + '.purposeOfEntity.geographicalArea' : 'purposeOfEntity.geographicalArea'} error={errors.purposeOfEntity?.countriesOfOperation} placeholder='Select at least one country' onChange={(val) => onSelectChange(val, namePrefix ? namePrefix + '.purposeOfEntity.countriesOfOperation' : 'purposeOfEntity.countriesOfOperation')} disabled={disabled} type='company' >
                            {Object.keys(countriesAndRegions).map((val, i) => {
                                const country = countriesAndRegions[val as keyof typeof countriesAndRegions]
                                return <Option key={i} value={val}>{country.name}</Option>
                            })}
                        </FormSelect>
                    </Col>
                </Row>
            }
            <FormInput showApprove={showApprove} label='Approximate annual turnover (EUR)' name={namePrefix ? namePrefix + '.purposeOfEntity.approximateAnnualTurnoverEur' : 'purposeOfEntity.approximateAnnualTurnoverEur'} error={errors.purposeOfEntity?.approximateAnnualTurnoverEur} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.purposeOfEntity?.approximateAnnualTurnoverEur} type='company' formatting='numbers' />
           
            <FormInput showApprove={showApprove} label='Estimated amount of future Crypto transactions with Bitclear p.a. (in EUR)' name={namePrefix ? namePrefix + '.purposeOfEntity.estimatedOfCryptoTransactionsEur' : 'purposeOfEntity.estimatedOfCryptoTransactionsEur'} error={errors.purposeOfEntity?.estimatedOfCryptoTransactionsEur} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.purposeOfEntity?.estimatedOfCryptoTransactionsEur} type='company' formatting='numbers' />
  		</Space>
	)
};

export default PurposeOfEntity;