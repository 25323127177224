import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import companyPaper from 'assets/companyPaper.png'
import { FC, ReactNode } from 'react'
import FontBold from 'styles/font/Poppins-Bold.ttf'
import FontNormal from 'styles/font/Poppins-Regular.ttf'
import { themeColor } from '../../../styles/themeStyles'

Font.register({
	family: 'Poppins',
	fonts: [
		{ src: FontNormal, fontWeight: 'normal' }, // font-style: normal, font-weight: normal
		{ src: FontBold, fontWeight: 'bold' },
	],
})

const splitArrayTo = <T=any>(array: T[], to: number):T[][] => {
	const data = [...array]
	const result = []
	while(data.length){
		result.push(data.splice(0,to))
	}
	return result
}

export const TitleSection:FC<{ level?: number }> = ({ level = 1, children })=>{
	const divider = level === 1 ? 0 : level
	const fontSize = 14 - divider + 'px'
	const marginBottom = 10 - divider + 'px'
	const marginTop = 20 - (2 * divider) + 'px'
	
	return <Text style={{ fontWeight: 'bold', fontSize, marginBottom, marginTop }}>{children}</Text>
}
// labels are presented in column for every signature
export const SignatureSection:FC<{ signatures: {labels: ReactNode[] }[]}> = ({ signatures })=>{
	return <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
		{signatures.map(signature =>
			<View style={{ width: '45%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderTop: '1px dashed #000000', marginTop: '60px'}}>
				{signature.labels.map((label,i) => <Text key={i} style={{ textAlign: 'center', fontSize: '7px' }}>{label}</Text>)}
			</View>)
		}
	</View>
}
export const ListSection:FC<{ points: ReactNode[] }> = ({ points })=>{
	return <View style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
		{points.map((value, i) =>
			<View key={`point-${i}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
				<View style={{ height: '100%' }}>
					<Text>{'\u2022' + " "}</Text>
				</View>
				<Text style={{}}>{value}</Text>
			</View>
		)}
	</View>
}
export const FieldsSection:FC<{ fields: {label?: ReactNode, value?: ReactNode}[], columns?: number }> = ({ columns = 1, fields })=>{
	const width = 100/columns - (columns-1) + '%' // split into columns and minus margin (for 1 column margin is 0, for 2 columns margin is 1, ect)
	
	return <>
		{splitArrayTo(fields, columns).map((row, i)=>{
			return <View key={i} wrap={false} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '5px'}}>
				{row.map((item, i) =>
					(item.label || item.value) ? <View key={i} style={{ width, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
						{item.label ? <Text style={{ fontWeight: 'bold' }}>{item.label}:</Text> : null}
						<Text>{item.value}</Text>
					</View> : null
				)}
			</View>
		})}
	</>
}

export type PdfContentProps = {
	title?: string
	generatedTime?: string
	children?: ReactNode
}
export const KycPdfContent: FC<PdfContentProps> = ({ title, generatedTime, children }) => {
	
	return (
		<Document
			title={title}
			author={'Bitclear AG'}
			creator={''}
			producer={''}
			pdfVersion={undefined}
			language={'en_GB'}
		>
			<Page
				size={'A4'}
				style={{
					fontFamily: 'Poppins',
					fontSize: '9px',
					fontWeight: 'normal',
					padding: '70px 70px 70px 70px',
					margin: '0',
				}}
			>
				{/* background paper image */}
				<Image fixed src={companyPaper} style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh' }} />
				{/* subtitle under logo image */}
				<Text style={{ fontSize: '10px', fontWeight: 'bold', color: themeColor.grayBlack, position: 'absolute', top: '48px', left: '70px' }}>KYC Application</Text>
				{/* pagination in the footer - positioned to be on top of logo 'B' */}
				<View style={{ textAlign: 'left', width: '11.5vw', position: 'absolute', bottom: '50px', right: '0'}} fixed>
					<Text render={({ pageNumber, totalPages }) => (`page ${pageNumber} of ${totalPages}`)} style={{ fontSize: '7px', fontWeight: 'normal', color: themeColor.grayBlack }} />
				</View>
				{/* generated on info in header */}
				{generatedTime ?
					<View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'absolute', top: '28px', right: '70px' }}>
						<Text style={{ fontSize: '7px' }}>Generated on:</Text>
						<Text style={{ fontSize: '7px' }}>{generatedTime}</Text>
					</View>
				: null}
				
				{children}
			</Page>
		</Document>
	)
}
