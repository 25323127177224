import { Space } from 'antd'
import moment from 'moment'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { CompanyBody, IndividualBody, ProfileResponse } from '../../../store/kyc-front/asyncThunks/kycApplicationGet'
import { downloadFile } from '../../../utilities/downloadFile'
import { ButtonUpper } from '../ui-elements/Buttons'
import { dateFormatDetailed } from '../ui-elements/FormDate'
import KycPdf from './KycPdf'
import { CompanyPdfContent, IndividualPdfContent } from './kycPdfData'

type CompanyProfile = ProfileResponse<CompanyBody>
type IndividualProfile = ProfileResponse<IndividualBody>
type Profile = CompanyProfile | IndividualProfile

const Wrapper = styled.div`
	text-align: center;
`
const isIndividual = (item: Profile):item is IndividualProfile => item.type === 'INDIVIDUAL'
const isCompany = (item: Profile):item is CompanyProfile => item.type === 'COMPANY'

interface IProps {
	item: Profile
}
export const KycGetPdfBtn: FC<IProps> = ({ item }) => {
	const [showPdf, setShowPdf] = useState<boolean>(false)
	const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
	const children = isIndividual(item) ? <IndividualPdfContent values={item.values} /> : isCompany(item) ? <CompanyPdfContent values={item.values}/> : []

	const onBtnClick = () => {
		setShowPdf(true)
		setIsBtnLoading(true)
	}
	const onReady = (url: string | null) => {
		setIsBtnLoading(false)
		if (url) {
			setShowPdf(false)
			downloadFile(url, `KYC-${item.clientId}.pdf`)
		}
	}

	return (
		<Wrapper>
			<Space direction={'vertical'} size={'middle'}>
				<ButtonUpper type={'primary'} onClick={onBtnClick} loading={isBtnLoading}>Download & print kyc</ButtonUpper>
				{showPdf && <KycPdf onUrl={onReady} title={`KYC - ${item.clientId}`} generatedTime={moment(new Date()).format(dateFormatDetailed).toString()}>
					{children}
				</KycPdf>}
			</Space>
		</Wrapper>
	)
}
