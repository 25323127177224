import type { InputRef } from 'antd';
import { Button, Form, Input, Popconfirm, Table, Select, Space, Empty } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import type { FormInstance } from 'antd/es/form'
import React, { useContext, useEffect, useRef, useState, FC } from 'react'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { countries } from 'countries-list'
import FormSelect from '../../../kyc-front/ui-elements/FormSelect'

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}


interface DataType {
    key: React.Key;
    name: string;
    dateOfBirth: string;
    nationality: string;
    residenceCountry: string;
    isCreating?: boolean;
}

interface CProps {
    list?: any[],
    name: string
}


const Directors: FC<CProps> = ({list, name}) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);

    const [count, setCount] = useState(0);
    const [initialized, setInitialized] = useState(false);


  const defaultColumns: ({ title: string, width?: string, editable?: boolean; dataIndex: string, select?: boolean, placeholder?: string, datepicker?: boolean })[] = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: '30%',
        editable: true,
    },
    {
        title: 'Date of birth',
        dataIndex: 'dateOfBirth',
        editable: true,
        datepicker: true
    },
    {
        title: 'Nationality',
        dataIndex: 'nationality',
        placeholder: 'Select country',
        editable: true,
        select: true
    },
    {
        title: 'Residence country',
        dataIndex: 'residenceCountry',
        placeholder: 'Select country',
        editable: true,
        select: true
    }
  ];
    useEffect(() => {
        if(list && !initialized) {
            let dataSourceList: any[] = [];
            let countCopy = 0;
            list.forEach((elem, i) => {
                let nationalityValues = "";
                if(elem.nationality) {
                    let elemLength = elem.nationality.split(",").length;
                    //@ts-ignore
                    elem.nationality.split(",").forEach((natElem, i) => {
                        //@ts-ignore
                        nationalityValues = nationalityValues + countries[natElem].name;
                        if(elemLength && (i + 1) < elemLength) {
                            nationalityValues += ", "
                        }
                        return natElem
                    })
                };
                dataSourceList.push({
                    key: countCopy,
                    name: elem.name,
                    dateOfBirth: elem.dob,
                    nationality: nationalityValues,
                    //@ts-ignore
                    residenceCountry: countries[elem.country]?.name
                });
                countCopy = countCopy + 1
            })
            setDataSource([...dataSourceList]);
            setInitialized(true);
            setCount(countCopy);
        }
    }, [list]);
  return (
    <Space direction='vertical' size={25}>
        {dataSource?.length ? (
            <Table
                className='directors-table'
                pagination={false}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource.map((elem, i) => {
                    return {
                        key: i,
                        name: elem.name,
                        dateOfBirth: elem.dateOfBirth,
                        nationality: elem.nationality,
                        residenceCountry: elem.residenceCountry
                    }
                })}
                columns={defaultColumns}
            />
        ) : (
            <Empty description={`No ${name}`} />
        )}
    </Space>
  );
};

export default Directors;
