import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Row, Col, Divider } from 'antd'
import { store, dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import React, { FC } from 'react'
import FormSelect from '../../../kyc-front/ui-elements/FormSelect'
import ApprovalSignature from '../../ui-elements/ApprovalSignature'
import { FormCheckboxRadio } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { companyBusinessProfileApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileApproveUpdate'
import { individualBusinessProfileApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileApproveUpdate'
import { companyBusinessProfileUpdate } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileUpdate'
import { individualBusinessProfileUpdate } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileUpdate'
import { companyBusinessProfileSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileSnapshotCreate'
import { individualBusinessProfileSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileSnapshotCreate'
import { 
	companyBusinessProfileSnapshotCreateClear,
	individualBusinessProfileSnapshotCreateClear,
	companyBusinessProfileUpdateClear,
	individualBusinessProfileUpdateClear
} from '../../../../store/kyc-backend/slices/businessProfileSlice'
import SourceOfFunds from './SourceOfFunds'
import PurposeOfEntity from './PurposeOfEntity'
import DirectorsTable from './DirectorsTable'
import BusinessProfileHistoryTable from './BusinessProfileHistoryTable'
import BasicFormWrapper from '../../../kyc-front/ui-elements/BasicForm'
import FormInput from '../../../kyc-front/ui-elements/FormInput'
import FormDate from '../../../kyc-front/ui-elements/FormDate'
import { DividerFirst } from '../../../kyc-front/ui-elements/Dividers'
import { themeColor } from '../../../../styles/themeStyles'

interface KProps {
    isEditable: boolean, 
    label: string, 
    name: string, 
    kycName?: string, 
    error: string, 
    handleInputChange: any, 
    disabled: boolean, 
    value: string, 
    kycValue?: string,
    spanCol?: number,
    isPrint?: boolean,
}


interface BProps {
    printRender: boolean,
    initialValues: any,
    snapStatus: any,
    isEditable: boolean,
    disabled: boolean,
    showApprove: boolean,
    authorizedPersonsUBOs: any,
    approvals: any,
    snapshotId: string
}


const KycInputElement: FC<KProps> = ({isEditable, label, name, kycName, error, handleInputChange, disabled, value, kycValue, spanCol, isPrint}) => (
		<Row gutter={30}>
        	<Col span={spanCol ? spanCol : isEditable ? 12 : 24}>
        		<FormInput showApprove={false} label={label} name={name}  error={error} onChange={handleInputChange} disabled={isPrint ? false : true} printRender={isPrint ? isPrint : false} value={value} type='company'  />
        	</Col>
        	{isEditable && <Col span={12}>
	        		<FormInput showApprove={false} label={label} name={kycName ? kycName : ''} disabled={isPrint ? false : true} printRender={isPrint ? isPrint : false} value={kycValue ? kycValue : ''} type='company'  />
	        	</Col>
	        }
        </Row>
)


const BusinessProfileForm: FC<BProps> = ({printRender, initialValues, snapStatus, isEditable, disabled, showApprove, authorizedPersonsUBOs, approvals, snapshotId, children}) => {
	const envs = useSelector((state) => state.general.envs);
	const isBusiness = envs.accountType === 'BUSINESS';
	const adminProfile = useSelector((state) => state.admin.admin);
	const businessProfile = useSelector((state) => state.admin.businessProfile);
	const validation = Yup.object({});
    let personsCount = authorizedPersonsUBOs?.directors?.length + authorizedPersonsUBOs?.beneficialOwners?.length + authorizedPersonsUBOs?.otherAuthorizedPersons?.length;
	return (
        <BasicFormWrapper 
            id='businessProfileForm' 
            /*@ts-ignore*/
            successCondition={isBusiness ? businessProfile.newBusinessProfileCompanySnapshot.length : businessProfile.newBusinessProfileIndividualSnapshot.length}
            /*@ts-ignore*/
            responseState={businessProfile}
            validation={validation}
            initial={initialValues} 
            printRender={false} 
            saveText='Create profile'
            saveDraft={true}
            standaloneFormSubmit={true}
            editable={false}
            //dispatchSubmit={isBusiness ? companyBusinessProfileSnapshotCreate : individualBusinessProfileSnapshotCreate}
            dispatchSubmit={!snapStatus.isDraft ? isBusiness ? companyBusinessProfileSnapshotCreate : individualBusinessProfileSnapshotCreate : isBusiness ? companyBusinessProfileUpdate : individualBusinessProfileUpdate}
            dispatchClear={!snapStatus.isDraft ? isBusiness ? companyBusinessProfileSnapshotCreateClear : individualBusinessProfileSnapshotCreateClear : isBusiness ? companyBusinessProfileUpdateClear : individualBusinessProfileUpdateClear}
            params={isBusiness ? {companyId: envs.profileId} : {individualId: envs.profileId}}
            hideOnDisabled={true}
        >
            {({handleInputChange, onSelectChange, onDateChange, values, errors, setFieldValue, initialErrorsValues}) => {
                return (
                    <>
                        <DividerFirst />
                        {printRender ? (
                        	<div className={printRender && isBusiness ? 'margin-min-print' : ''}><Title level={2}>Client Business Profile</Title></div>
                        ) : (
                        	<Title level={3}>Basic {isBusiness && 'Company '}Information</Title>
                        )}
                       	<KycInputElement isPrint={printRender} isEditable={isEditable} label={isBusiness ? 'Name of legal entity ' : 'Client name'} name='basicInformation.name' kycName='user.basicInformation.name' error={errors.basicInformation?.name} handleInputChange={handleInputChange} disabled={disabled} value={values.basicInformation?.name} kycValue={values.user.basicInformation.name} />
                        <Row gutter={30}>
                        	{isBusiness && <Col span={isEditable ? 24 : printRender ? 12 : 24}>
			                    <KycInputElement isPrint={printRender} isEditable={isEditable} label='Legal form' name='basicInformation.legalForm' kycName='user.basicInformation.legalForm' error={errors.basicInformation?.legalForm} handleInputChange={handleInputChange} disabled={disabled} value={values.basicInformation?.legalForm} kycValue={values.user.basicInformation.legalForm} />
		                    </Col>}
                        	<Col span={isEditable ? 12 : printRender && isBusiness ? 12 : 24} style={printRender ? {} : {marginTop: 20}}>
                        		<FormInput showApprove={showApprove} label='Public key (mandatory for VT services)' name='basicInformation.publicKey'  error={errors.basicInformation?.publicKey} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.basicInformation?.publicKey} type='company' />
                        	</Col>
                        </Row>
                        <Divider />
                        {printRender ? (<></>) : (<Title level={3} className='title4'>{isBusiness ? 'Registered address' : 'Address'}</Title>)}
                        <div className={printRender ? 'margin-5-print' : ''}>
		                	<KycInputElement isPrint={printRender} isEditable={isEditable} label='Address' name='basicInformation.address' kycName='user.basicInformation.address' error={errors.basicInformation?.address} handleInputChange={handleInputChange} disabled={disabled} value={values.basicInformation?.address} kycValue={values.user.basicInformation.address} />
		                </div>
		                <Row gutter={30}>
		                	<Col span={isEditable ? 12 : 24}>
		                        <Row gutter={30}>
		                           <Col span={12}>
		                               <FormInput showApprove={showApprove} label='Postcode' name='basicInformation.postcode' error={errors.basicInformation?.postcode} onChange={handleInputChange} disabled={true} printRender={printRender} value={values.basicInformation?.postcode} type='company' />
		                           </Col>
		                           <Col span={12}>
		                               <FormInput showApprove={showApprove} label='City' name='basicInformation.city' error={errors.basicInformation?.city} onChange={handleInputChange} disabled={true} printRender={printRender} value={values.basicInformation?.city} type='company' />
		                           </Col>
		                        </Row>
		                    </Col>
		                    {isEditable &&
			                	<Col span={12}>
			                        <Row gutter={30}>
			                           <Col span={12}>
			                               <FormInput showApprove={showApprove} label='Postcode' name='basicInformation.postcode' disabled={true} printRender={false} value={values.user.basicInformation?.postcode} type='company' />
			                           </Col>
			                           <Col span={12}>
			                               <FormInput showApprove={showApprove} label='City' name='basicInformation.city' disabled={true} printRender={false} value={values.user.basicInformation?.city} type='company' />
			                           </Col>
			                        </Row>
			                    </Col>
			                }
		                </Row>
		                {isBusiness && 
			                <Row gutter={30}>
			                	<Col span={isEditable ? 12 : 24}>
		                        	<Row gutter={30}>
			                           <Col span={12}>
			                               <FormDate hideApprove={!showApprove} label='Date of incorporation' id='basicInformation.dateOfIncorporation'   error={errors.basicInformation?.dateOfIncorporation} defaultValue={values.basicInformation?.dateOfIncorporation ? moment(values.basicInformation?.dateOfIncorporation, 'YYYY-MM-DD') : undefined} onChange={(val) => onDateChange(val, 'basicInformation.dateOfIncorporation')} disabled={true}  type='company' />
			                           </Col>
			                           <Col span={12}>
			                               <FormInput showApprove={showApprove} label='Registration number' name='basicInformation.registrationNumber'   error={errors.basicInformation?.registrationNumber} onChange={handleInputChange} disabled={true} printRender={printRender} value={values.basicInformation?.registrationNumber} type='company' />
			                           </Col>
			                        </Row>
				                </Col>
				                {isEditable &&
				                	<Col span={12}>
			                        	<Row gutter={30}>
				                           <Col span={12}>
				                               <FormDate hideApprove={!showApprove} label='Date of incorporation' id='basicInformation.dateOfIncorporation'   error={errors.basicInformation?.dateOfIncorporation} defaultValue={values.basicInformation?.dateOfIncorporation ? moment(values.basicInformation?.dateOfIncorporation, 'YYYY-MM-DD') : undefined} onChange={(val) => onDateChange(val, 'basicInformation.dateOfIncorporation')} disabled={true}  type='company' />
				                           </Col>
				                           <Col span={12}>
				                               <FormInput showApprove={showApprove} label='Registration number' name='basicInformation.registrationNumber'   error={errors.basicInformation?.registrationNumber} onChange={handleInputChange} disabled={true} printRender={printRender} value={values.basicInformation?.registrationNumber} type='company' />
				                           </Col>
				                        </Row>
					                </Col>
					            }
				            </Row>
				        }
				        {isBusiness &&
		                    <KycInputElement isPrint={printRender} isEditable={isEditable} label='Place of incorporation' name='basicInformation.placeOfIncorporation' kycName='user.basicInformation.placeOfIncorporation' error={errors.basicInformation?.placeOfIncorporation} handleInputChange={handleInputChange} disabled={disabled} value={values.basicInformation?.placeOfIncorporation} kycValue={values.user.basicInformation.placeOfIncorporation} />
		                }
                        {isBusiness && <Divider />}
                        <div className={printRender && isBusiness ? 'margin-min-print' : ''}>
		                    <Row justify='space-between'>
	                   			<Title level={3} className='sourceOfFunds'>Source of Funds (SoF) / Source of Wealth (Sow):</Title>
	                   		</Row>
                   		</div>
                   		<Row gutter={30}>
                   			<Col span={isEditable ? 12 : 24}>
		                   		<SourceOfFunds 
		                   			showApprove={showApprove} 
		                   			errors={errors} 
		                   			handleInputChange={handleInputChange} 
		                   			disabled={disabled} 
		                   			values={values} 
		                   			printRender={printRender} 
		                   			namePrefix=''
		                   			isBusiness={isBusiness}
		                   		/>
	                   		</Col>
	                   		{isEditable &&
		                   		<Col span={12}>
			                   		<SourceOfFunds 
			                   			showApprove={showApprove} 
			                   			errors={errors} 
			                   			handleInputChange={handleInputChange} 
			                   			disabled={true} 
			                   			values={values.user} 
			                   			printRender={printRender} 
			                   			namePrefix='user'
			                   			isBusiness={isBusiness}
			                   		/>
		                   		</Col>
		                   	}
                   		</Row>
                   		<Row gutter={30}>
	                   		<Col span={isEditable ? 12 : 24}>
	                   			<FormInput label='Effective Contributor(s) of funds' showApprove={showApprove} name={'sourceOfFunds.effectiveContributorOfFunds'} error={errors.sourceOfFunds?.effectiveContributorOfFunds} onChange={handleInputChange} disabled={disabled} value={values.sourceOfFunds?.effectiveContributorOfFunds} printRender={printRender} type='company' />
	                   		</Col>
	                   	</Row>
	                   	<Row gutter={30}>
	                   		<Col span={isEditable ? 12 : 24}>
	                   			<FormInput className={printRender && isBusiness ? 'businessBackground' : ''} showApprove={showApprove} label='Business background and job of the effective contributor(s) (SoW)' name={'sourceOfFunds.businessBackgroundOfContributor'} error={errors.sourceOfFunds?.businessBackgroundOfContributor} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.sourceOfFunds?.businessBackgroundOfContributor} printRender={printRender} type='company' />
	                   		</Col>
                   		</Row>
                   		<Divider />
                   		<Row justify='space-between'>
                   			<div className={printRender ? 'margin-purpose-print' : ''}>
                   				<Title level={3}>Purpose of Entity / Use of Funds:</Title>
                   			</div>
                   		</Row>
	                  	{isBusiness &&
	                  		<KycInputElement isPrint={printRender} isEditable={isEditable} label='Corporate website' name='purposeOfEntity.website' kycName='user.purposeOfEntity.website' error={errors.purposeOfEntity?.website} handleInputChange={handleInputChange} disabled={disabled} value={values.purposeOfEntity.website} kycValue={values.user.purposeOfEntity.website} />
	                  	}
                  		<Row gutter={30}>
                   			<Col span={isEditable ? 12 : 24}>
		                  		<PurposeOfEntity 
		                  			isBusiness={isBusiness} 
		                  			showApprove={showApprove} 
		                   			errors={errors} 
		                   			handleInputChange={handleInputChange} 
		                   			disabled={disabled} 
		                   			values={values} 
		                   			printRender={printRender} 
		                   			onSelectChange={onSelectChange}
		                   			namePrefix=''
		                   		/>
		                   	</Col>
		                   	{isEditable &&
		                   		<Col span={12}>
			                   		<PurposeOfEntity 
			                  			isBusiness={isBusiness} 
			                  			showApprove={showApprove} 
			                   			errors={errors} 
			                   			handleInputChange={handleInputChange} 
			                   			disabled={true} 
			                   			values={values.user} 
			                   			printRender={printRender} 
			                   			onSelectChange={onSelectChange}
			                   			namePrefix='user'
			                   		/>
		                   		</Col>
		                   	}
		                   	
		                </Row>
		                <Row gutter={30}>
		                	<Col span={isEditable ? 12 : 24}>
				                <label style={{marginBottom: 20, display: 'block'}}>Purpose of business relationship</label>
				                <FormCheckboxRadio showApprove={showApprove} label='Purpose of business relationship' id={'purposeOfEntity.purposeOfBusinessRelationship'} error={errors.purposeOfEntity?.purposeOfBusinessRelationship} type='radio' onChange={handleInputChange}
					                options={[{
					                        label: 'Crypto payment processing via Bitclear Software',
					                        value: 'cryptoProcessing'
					                    },
					                    {
					                        label: 'Other',
					                        value: 'other'
					                    }
					                ]
					            } disabled={disabled} approveType='company' />
				            </Col>
			            </Row>
			            <Row gutter={30}>
			            	<Col span={isEditable ? 12 : 24}>
	                    		<FormInput showApprove={showApprove} label='Name(s) of main bank(s)' name='purposeOfEntity.banks'  error={errors.purposeOfEntity?.banks} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.purposeOfEntity?.banks} type='company' />
                   			</Col>
                   		</Row>
                   		{isBusiness && 
                   			<>
                   				<Divider />
		                   		<Title level={3}>Authorized Persons and UBOs</Title>
		                   		{!printRender || (printRender && authorizedPersonsUBOs?.directors.length) ? (
			                   		<>
				                   		<Title level={4}>Directors</Title>
				                   		<Row>
					                   		<Col span={24}>
						                   		<DirectorsTable
						                   			list={authorizedPersonsUBOs?.directors}
						                   			name='Directors'
						                   		 />
					                   		</Col>
				                   		</Row>
			                   		</>
		                   		) : (
		                   			<></>
		                   		)}
		                   		<Divider />
		                   		{!printRender || printRender && authorizedPersonsUBOs?.otherAuthorizedPersons.length ? (
		                   			<>
				                   		<Title level={4}>Other Authorized Persons</Title>
				                   		<Row>
					                   		<Col span={24}>
						                   		<DirectorsTable
						                   			list={authorizedPersonsUBOs?.otherAuthorizedPersons}
						                   			name='Authorized Persons'
						                   		 />
					                   		</Col>
				                   		</Row>
				                   		<Divider />
			                   		</>
			                   	) : (
			                   		<></>
			                   	)}
			                   	{!printRender || printRender && authorizedPersonsUBOs?.beneficialOwners.length ? (
			                   		<>
				                   		<Title level={4}>Ultimate Beneficial Owners</Title>
				                   		<Row>
					                   		<Col span={24}>
						                   		<DirectorsTable
						                   			list={authorizedPersonsUBOs?.beneficialOwners}
						                   			name='Beneficial Owners'
						                   		 />
					                   		</Col>
				                   		</Row>
		                   			</>
		                   		)  : (
			                   		<></>
			                   	)}
		                   		<br />
                   				
		                   	</>
		                }
		                <div className={(personsCount < 5 && personsCount > 2) ? 'margin-approvals' : ''}>
		                	<Divider style={{marginTop: '-20px', marginBottom: '40px'}} />
		                	<Title level={3} className='approvalsTitle'>Business Profile Approvals</Title>
		                </div>
                  		<Row >
                  			<Col>
		                  		<ApprovalSignature 
		                  			approveUpdate={isBusiness ? companyBusinessProfileApproveUpdate : individualBusinessProfileApproveUpdate}
		                  			approveParams={isBusiness ? {companyId: envs.profileId, businessProfileSnapshotId: snapshotId} : {individualId: envs.profileId, businessProfileSnapshotId: snapshotId}}
		                  			isEditable={snapStatus.isEditable}
		                  			title="Client Advisor" 
		                  			type="ClientAdvisorApproval" 
		                  			value={approvals.clientAdvisorApprovedBy}
		                  			time={approvals.clientAdvisorApprovedAt}
		                  			snapshotId={snapshotId} 
		                  			disabled={approvals.complianceOfficeApprovedBy !== undefined && approvals.complianceOfficeApprovedBy == adminProfile.profile.adminId}
		                  		/>
						    </Col>
						    <Col>
							    <ApprovalSignature 
							    	approveUpdate={isBusiness ? companyBusinessProfileApproveUpdate : individualBusinessProfileApproveUpdate}
							    	approveParams={isBusiness ? {companyId: envs.profileId, businessProfileSnapshotId: snapshotId} : {individualId: envs.profileId, businessProfileSnapshotId: snapshotId}}
							    	isEditable={snapStatus.isEditable}
							    	title="Compliance Officer" 
							    	type="ComplianceOfficerApproval" 
							    	value={approvals.complianceOfficeApprovedBy}
							    	time={approvals.complianceOfficeApprovedAt}
							    	snapshotId={snapshotId}
							    	disabled={approvals.clientAdvisorApprovedBy !== undefined && approvals.clientAdvisorApprovedBy == adminProfile.profile.adminId}
							    />
						    </Col>
					    </Row>
					    {children}
                  	</>
                )
            }}

        </BasicFormWrapper>
			 
	)
}

export default BusinessProfileForm
