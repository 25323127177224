import React, { FC } from 'react'
import FormInput from '../../../kyc-front/ui-elements/FormInput'
import { FormCheckboxRadio } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { Divider, Space } from 'antd'

interface CProps {
    errors: any,
    values: any,
    showApprove: boolean,
    handleInputChange: () => void,
    disabled: boolean,
    printRender: boolean,
    namePrefix?: string,
    isBusiness: boolean
}


const SourceOfFunds: FC<CProps> = ({showApprove, errors, handleInputChange, disabled, values, printRender, namePrefix, isBusiness}) => {
	return (
		<Space direction='vertical' size={24}>
			
	   		 <div className={printRender && isBusiness ? 'checkboxCompanyAddressContainer' : ''}>
				<label style={{marginBottom: printRender && isBusiness ? 0 : 20, display: 'block'}}>Funding of business activities (SoF)</label>
		   		<FormCheckboxRadio showApprove={showApprove} className={'checkboxCompanyAddress'} label='Funding of business activities (SoF)' id={namePrefix ? namePrefix + '.sourceOfFunds.fundingOfBusinessActivities' : 'sourceOfFunds.fundingOfBusinessActivities'} error={errors.sourceOfFunds?.fundingOfBusinessActivities} type='radio' onChange={handleInputChange}
		            options={[{
		                    label: 'equity/debt contribution by UBO',
		                    value: 'EquityDebtContributionByUBO'
		                },
		                {
		                    label: 'other',
		                    value: 'OtherFundingOfBusinessType'
		                }
		            ]
		        } disabled={disabled} approveType='company' />
	        </div>
	        <FormInput showApprove={showApprove} label='Further explanation (mandatory if not by UBO)' name={namePrefix ? namePrefix + '.sourceOfFunds.furtherExplanation' : 'sourceOfFunds.furtherExplanation'} error={errors.sourceOfFunds?.furtherExplanation} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.sourceOfFunds?.furtherExplanation} printRender={printRender} type='company' />
  		</Space>
	)
};

export default SourceOfFunds;