import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { companyBusinessProfileGet } from "../asyncThunks/companyBusinessProfileGet";
import { companyBusinessProfileDraftGet } from "../asyncThunks/companyBusinessProfileDraftGet";
import { companyBusinessProfileSnapshotsGet } from "../asyncThunks/companyBusinessProfileSnapshotsGet";
import { companyBusinessProfileSnapshotCreate } from "../asyncThunks/companyBusinessProfileSnapshotCreate";
import { companyBusinessProfileFromKycGet } from "../asyncThunks/companyBusinessProfileFromKycGet";
import { individualBusinessProfileGet } from "../asyncThunks/individualBusinessProfileGet";
import { individualBusinessProfileSnapshotsGet } from "../asyncThunks/individualBusinessProfileSnapshotsGet";
import { individualBusinessProfileDraftGet } from "../asyncThunks/individualBusinessProfileDraftGet";
import { individualBusinessProfileSnapshotCreate } from "../asyncThunks/individualBusinessProfileSnapshotCreate";
import { individualBusinessProfileFromKycGet } from "../asyncThunks/individualBusinessProfileFromKycGet";
import { companyBusinessProfileApproveUpdate } from "../asyncThunks/companyBusinessProfileApproveUpdate";
import { individualBusinessProfileApproveUpdate } from "../asyncThunks/individualBusinessProfileApproveUpdate";
import { individualBusinessProfileUpdate } from "../asyncThunks/individualBusinessProfileUpdate";
import { companyBusinessProfileUpdate } from "../asyncThunks/companyBusinessProfileUpdate";
import { companyBusinessProfileArchive } from "../asyncThunks/companyBusinessProfileArchive";
import { individualBusinessProfileArchive } from "../asyncThunks/individualBusinessProfileArchive";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type ProfileResponse = Components.Schemas.BusinessProfileSnapshot;
export type BusinessProfileResponse = Components.Schemas.BusinessProfile;
type AuthorizedPersonsUBOs = Components.Schemas.AuthorizedPersonsUBOs;
type ArchiveResponse = Paths.ArchiveCompanyBusinessProfileSnapshot.Responses.$200;

type State = {
  businessProfileCompany: ProfileResponse,
  businessProfileIndividual: ProfileResponse,
  businessProfileCompanyDraft: ProfileResponse,
  businessProfileIndividualDraft: ProfileResponse,
  businessProfileCompanySpanshots: ProfileResponse[],
  businessProfileIndividualSpanshots: ProfileResponse[],
  newBusinessProfileCompanySnapshot: ProfileResponse,
  newBusinessProfileIndividualSnapshot: ProfileResponse,
  businessProfileCompanyKYC: BusinessProfileResponse,
  businessProfileIndividualKYC: BusinessProfileResponse,
  businessProfileCompanyApproved: ProfileResponse,
  businessProfileIndividualApproved: ProfileResponse,
  updatedCompanyBusinessProfile: ProfileResponse,
  updatedIndividualBusinessProfile: ProfileResponse
  businessProfileCompanyArchived: ArchiveResponse,
  businessProfileIndividualArchived: ArchiveResponse,
};

const emptyProfile = {
    snapshotId: '',
    createdAt: '',
    dataVersion: '',
    data: ''
}

const emptyBusinessProfile = {
    basicInformation: {},
    authorizedPersonsUBOs: {},
    sourceOfFunds: {},
    purposeOfEntity: {}
}

const initialState: State = {
    businessProfileCompany: emptyProfile,
    businessProfileIndividual: emptyProfile,
    businessProfileCompanyDraft: emptyProfile,
    businessProfileIndividualDraft: emptyProfile,
    businessProfileCompanySpanshots: [],
    businessProfileIndividualSpanshots: [],
    newBusinessProfileCompanySnapshot: emptyProfile,
    newBusinessProfileIndividualSnapshot: emptyProfile,
    businessProfileCompanyKYC: emptyBusinessProfile,
    businessProfileIndividualKYC: emptyBusinessProfile,
    businessProfileCompanyApproved: emptyProfile,
    businessProfileIndividualApproved: emptyProfile,
    updatedCompanyBusinessProfile: emptyProfile,
    updatedIndividualBusinessProfile: emptyProfile,
    businessProfileCompanyArchived: false,
    businessProfileIndividualArchived: false,
};

type CaseReducers<State> = {
    companyBusinessProfileGetClear: CaseReducerType<State, object>;
    individualBusinessProfileGetClear: CaseReducerType<State, object>;
    companyBusinessProfileDraftGetClear: CaseReducerType<State, object>;
    individualBusinessProfileDraftGetClear: CaseReducerType<State, object>;
    companyBusinessProfileSnapshotsGetClear: CaseReducerType<State, object>;
    individualBusinessProfileSnapshotsGetClear: CaseReducerType<State, object>;
    companyBusinessProfileSnapshotCreateClear: CaseReducerType<State, object>;
    individualBusinessProfileSnapshotCreateClear: CaseReducerType<State, object>;
    individualBusinessProfileFromKycGetClear: CaseReducerType<State, object>;
    companyBusinessProfileFromKycGetClear: CaseReducerType<State, object>;
    companyBusinessProfileApproveUpdateClear: CaseReducerType<State, object>;
    individualBusinessProfileApproveUpdateClear: CaseReducerType<State, object>;
    companyBusinessProfileUpdateClear: CaseReducerType<State, object>;
    individualBusinessProfileUpdateClear:  CaseReducerType<State, object>;
    companyBusinessProfileArchiveClear: CaseReducerType<State, object>;
    individualBusinessProfileArchiveClear: CaseReducerType<State, object>;
};

export const businessProfileSlice = createSlice<State, CaseReducers<State>>({
    name: "businessProfile",
    initialState,
    reducers: {
        companyBusinessProfileGetClear: (state, { payload }) => {
            state.businessProfileCompany = initialState.businessProfileCompany
        },
        individualBusinessProfileGetClear: (state, { payload }) => {
            state.businessProfileIndividual = initialState.businessProfileIndividual
        },
        companyBusinessProfileDraftGetClear: (state, { payload }) => {
            state.businessProfileCompanyDraft = initialState.businessProfileCompanyDraft
        },
        individualBusinessProfileDraftGetClear: (state, { payload }) => {
            state.businessProfileIndividualDraft = initialState.businessProfileIndividualDraft
        },
        companyBusinessProfileSnapshotsGetClear: (state, { payload }) => {
            state.businessProfileCompanySpanshots = initialState.businessProfileCompanySpanshots
        },
        individualBusinessProfileSnapshotsGetClear: (state, { payload }) => {
            state.businessProfileIndividualSpanshots = initialState.businessProfileIndividualSpanshots
        },
        companyBusinessProfileSnapshotCreateClear: (state, { payload }) => {
            state.newBusinessProfileCompanySnapshot = initialState.newBusinessProfileCompanySnapshot
        },
        individualBusinessProfileSnapshotCreateClear: (state, { payload }) => {
            state.newBusinessProfileIndividualSnapshot = initialState.newBusinessProfileIndividualSnapshot
        },
        companyBusinessProfileFromKycGetClear: (state, { payload }) => {
            state.businessProfileCompanyKYC = initialState.businessProfileCompanyKYC
        },
        individualBusinessProfileFromKycGetClear: (state, { payload }) => {
            state.businessProfileIndividualKYC = initialState.businessProfileIndividualKYC
        },
        companyBusinessProfileApproveUpdateClear: (state, { payload }) => {
            state.businessProfileCompanyApproved = initialState.businessProfileCompanyApproved
        },
        individualBusinessProfileApproveUpdateClear: (state, { payload }) => {
            state.businessProfileIndividualApproved = initialState.businessProfileIndividualApproved
        },
        companyBusinessProfileUpdateClear: (state, { payload }) => {
            state.updatedCompanyBusinessProfile = initialState.updatedCompanyBusinessProfile
        },
        individualBusinessProfileUpdateClear: (state, { payload }) => {
            state.updatedIndividualBusinessProfile = initialState.updatedCompanyBusinessProfile
        },
        companyBusinessProfileArchiveClear: (state, { payload }) => {
            state.businessProfileCompanyArchived = initialState.businessProfileCompanyArchived
        },
        individualBusinessProfileArchiveClear: (state, { payload }) => {
            state.businessProfileIndividualArchived = initialState.businessProfileIndividualArchived
        },
    },
    extraReducers: (builder) => {
        builder.addCase(companyBusinessProfileGet.fulfilled, (state, action) => {
            state.businessProfileCompany = action.payload
        });
        builder.addCase(individualBusinessProfileGet.fulfilled, (state, action) => {
            state.businessProfileIndividual = action.payload
        });
        builder.addCase(companyBusinessProfileDraftGet.fulfilled, (state, action) => {
            state.businessProfileCompanyDraft = action.payload
        });
        builder.addCase(individualBusinessProfileDraftGet.fulfilled, (state, action) => {
            state.businessProfileIndividualDraft = action.payload
        });
        builder.addCase(companyBusinessProfileSnapshotsGet.fulfilled, (state, action) => {
            state.businessProfileCompanySpanshots = action.payload
        });
        builder.addCase(individualBusinessProfileSnapshotsGet.fulfilled, (state, action) => {
            state.businessProfileIndividualSpanshots = action.payload
        });
        builder.addCase(companyBusinessProfileSnapshotCreate.fulfilled, (state, action) => {
            state.newBusinessProfileCompanySnapshot = action.payload
        });
        builder.addCase(individualBusinessProfileSnapshotCreate.fulfilled, (state, action) => {
            state.newBusinessProfileIndividualSnapshot = action.payload
        });
        builder.addCase(companyBusinessProfileFromKycGet.fulfilled, (state, action) => {
            state.businessProfileCompanyKYC = action.payload
        });
        builder.addCase(individualBusinessProfileFromKycGet.fulfilled, (state, action) => {
            state.businessProfileIndividualKYC = action.payload
        });
        builder.addCase(companyBusinessProfileApproveUpdate.fulfilled, (state, action) => {
            state.businessProfileCompanyApproved = action.payload
        });
        builder.addCase(individualBusinessProfileApproveUpdate.fulfilled, (state, action) => {
            state.businessProfileIndividualApproved = action.payload
        });
        builder.addCase(companyBusinessProfileUpdate.fulfilled, (state, action) => {
            state.updatedCompanyBusinessProfile = action.payload
        });
        builder.addCase(individualBusinessProfileUpdate.fulfilled, (state, action) => {
            state.updatedIndividualBusinessProfile = action.payload
        });
        builder.addCase(companyBusinessProfileArchive.fulfilled, (state, action) => {
            state.businessProfileCompanyArchived = action.payload
        });
        builder.addCase(individualBusinessProfileArchive.fulfilled, (state, action) => {
            state.businessProfileIndividualArchived = action.payload
        });
    }
});

export const { 
    companyBusinessProfileGetClear, 
    individualBusinessProfileGetClear, 
    companyBusinessProfileSnapshotsGetClear, 
    individualBusinessProfileSnapshotsGetClear, 
    companyBusinessProfileSnapshotCreateClear, 
    individualBusinessProfileSnapshotCreateClear,
    companyBusinessProfileDraftGetClear,
    individualBusinessProfileDraftGetClear,
    companyBusinessProfileFromKycGetClear,
    individualBusinessProfileFromKycGetClear,
    companyBusinessProfileApproveUpdateClear,
    individualBusinessProfileApproveUpdateClear,
    companyBusinessProfileUpdateClear,
    individualBusinessProfileUpdateClear,
    companyBusinessProfileArchiveClear,
    individualBusinessProfileArchiveClear
}  = businessProfileSlice.actions;
export default businessProfileSlice.reducer;
