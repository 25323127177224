import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ProfileResponse } from "../slices/businessProfileSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type RequestBody = Components.Schemas.BusinessProfile;

type PathParameters = Paths.UpdateIndividualBusinessProfileSnapshot.PathParameters;


export const individualBusinessProfileUpdate: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "params">
> = createAsyncThunk(
  "individualBusinessProfileUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.UpdateIndividualBusinessProfileSnapshot(params, data, config);
      return response.data as ProfileResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
