import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ProfileResponse } from '../slices/businessProfileSlice'
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type CreateBPRequest = Components.Schemas.BusinessProfile;
type PathParameters = Paths.CreateIndividualBusinessProfileSnapshot.PathParameters;

export const individualBusinessProfileSnapshotCreate: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, CreateBPRequest, "params">
> = createAsyncThunk(
  "individualBusinessProfileSnapshotCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CreateIndividualBusinessProfileSnapshot(params, data, config);
      return response.data as ProfileResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
