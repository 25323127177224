import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { ProfileResponse } from '../slices/businessProfileSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PathParameters = Paths.GetLatestDraftIndividualBusinessProfileSnapshot.PathParameters;

export const individualBusinessProfileDraftGet: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("individualBusinessProfileDraftGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.GetLatestDraftIndividualBusinessProfileSnapshot(data.params, null, data?.config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
